import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar/Navbar"
import "../styles/subteams.css"
import StructuresImage from "../images/WIP_image.png"


const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: minmax(0px, auto);
    grid-gap: 1em;
    color: #F5F5F7;
    font-family: 'Roboto';
    position: relative;
    text-align: left;
    top: 150px;
    margin-left: 100px
`
const SubteamsPage = () => (
  <Layout>
    <SEO title="Subteams" />
    <Navbar />
    <Wrapper>
        <h1> SUBTEAMS </h1>
        <br />
        <a href="/avionics_team/" className="subteam-link"> Avionics </a>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The Avionics team designs and builds the hardware on which our programming runs. Sensors, power regulation, and handling actuation are all the purview of Avionics.</td>
        </tr></table>
        <a href="/programming_team/" className="subteam-link"> Programming </a>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The Programming team creates and maintains the software that gives commands to our systems, and that allows us to receive and visualize data from our systems.</td>
        </tr></table>
        <a href="/propulsion_team/" className="subteam-link"> Propulsion </a>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The Propulsion team researches, designs, and builds the systems that allow our rocket to move.</td>
        </tr></table>
        <a href="/structures_team/" className="subteam-link"> Structures </a>
        <table className="subteam-description"><tr>
        <td><img src={StructuresImage} alt="Structures Team" className="subteam-image"/></td>
        <td>The Structures team researches, designs, and builds the load-bearing components of our test stand and our rocket.</td>
        </tr></table>
        <br />
    </Wrapper>
  </Layout>
)

export default SubteamsPage
